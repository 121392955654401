.react-rotating-text-cursor {
    width: 1px;
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@-moz-keyframes blinking-cursor {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes blinking-cursor {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes blinking-cursor {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes blinking-cursor {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.headerLinks a {
    color: white;
    text-decoration: none;
}

.headerLinks a:hover {
    color: rgb(241, 236, 236);
    text-decoration: underline;
    text-decoration-color: white;
    text-shadow: 2px 2px #111111;
    text-decoration-thickness: 3px;
}

.offscreen {
    left: -9999px;
    overflow: hidden;
    position: absolute;
}