/* 
The reason for the following CSS is to best guess the height of the components that will be loaded in the page. 
This is done to ensure that even thought the components probably won't have been lazy loaded yet, screen space is reserved for them.
This means that the IntersectionObserver based code that loads the components as they are about to displayed only "sees" the next
one of two components "below" where the user is currentluy scrolled to.
Note - see the App.js file for code that removes the min-heights set below when the user comes back to an already scrolled down page.
*/

/* Extra large devices (large desktops)  - the default settings */
.photoCardsPlaceholder {
    min-height: 632px;
}

.plantPhotoCardsPlaceholder {
    min-height: 632px;
}

.mapsCarouselPlaceholder {
    min-height: 480px;
}

.pictureGalleryPlaceholder {
    min-height: 873px;
}

.cyclingStatsPlaceholder {
    min-height: 701px;
}

.gpsMapsNearestPlaceholder {
    min-height: 632px;
}

.booksCoverflowPlaceholder {
    min-height: 600px;
}

.musicLastFMPlaceholder {
    min-height: 1097px;
}

.musicSoundcloudPlaceholder {
    min-height: 521px;
}

.softwareDevPlaceholder {
    min-height: 1362px;
}

.contactFormPlaceholder {
    min-height: 607px;
}

.footerPlaceholder {
    min-height: 353px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199px) {

    .photoCardsPlaceholder {
        min-height: 628px;
    }

    .plantPhotoCardsPlaceholder {
        min-height: 628px;
    }

    .mapsCarouselPlaceholder {
        min-height: 480px;
    }

    .pictureGalleryPlaceholder {
        min-height: 898px;
    }

    .cyclingStatsPlaceholder {
        min-height: 751px;
    }

    .gpsMapsNearestPlaceholder {
        min-height: 632px;
    }

    .booksCoverflowPlaceholder {
        min-height: 600px;
    }

    .musicLastFMPlaceholder {
        min-height: 1423px;
    }

    .musicSoundcloudPlaceholder {
        min-height: 571px;
    }

    .softwareDevPlaceholder {
        min-height: 1625px;
    }

    .contactFormPlaceholder {
        min-height: 619px;
    }

    .footerPlaceholder {
        min-height: 353px;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991px) {
    .photoCardsPlaceholder {
        min-height: 486px;
    }

    .plantPhotoCardsPlaceholder {
        min-height: 486px;
    }

    .mapsCarouselPlaceholder {
        min-height: 730px;
    }

    .pictureGalleryPlaceholder {
        min-height: 898px;
    }

    .cyclingStatsPlaceholder {
        min-height: 1183px;
    }

    .gpsMapsNearestPlaceholder {
        min-height: 881px;
    }

    .booksCoverflowPlaceholder {
        min-height: 826px;
    }

    .musicLastFMPlaceholder {
        min-height: 1423px;
    }

    .musicSoundcloudPlaceholder {
        min-height: 752px;
    }

    .softwareDevPlaceholder {
        min-height: 1625px;
    }

    .contactFormPlaceholder {
        min-height: 970px;
    }

    .footerPlaceholder {
        min-height: 390px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
    .photoCardsPlaceholder {
        min-height: 638px;
    }

    .plantPhotoCardsPlaceholder {
        min-height: 638px;
    }

    .mapsCarouselPlaceholder {
        min-height: 660px;
    }

    .pictureGalleryPlaceholder {
        min-height: 898px;
    }

    .cyclingStatsPlaceholder {
        min-height: 751px;
    }

    .gpsMapsNearestPlaceholder {
        min-height: 632px;
    }

    .booksCoverflowPlaceholder {
        min-height: 876px;
    }

    .musicLastFMPlaceholder {
        min-height: 1423px;
    }

    .musicSoundcloudPlaceholder {
        min-height: 752px;
    }

    .softwareDevPlaceholder {
        min-height: 1625px;
    }

    .contactFormPlaceholder {
        min-height: 619px;
    }

    .footerPlaceholder {
        min-height: 353px;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
    .photoCardsPlaceholder {
        min-height: 654px;
    }

    .plantPhotoCardsPlaceholder {
        min-height: 654px;
    }

    .mapsCarouselPlaceholder {
        min-height: 676px;
    }

    .pictureGalleryPlaceholder {
        min-height: 931px;
    }

    .cyclingStatsPlaceholder {
        min-height: 1267px;
    }

    .gpsMapsNearestPlaceholder {
        min-height: 906px;
    }

    .booksCoverflowPlaceholder {
        min-height: 858px;
    }

    .musicLastFMPlaceholder {
        min-height: 1423px;
    }

    .musicSoundcloudPlaceholder {
        min-height: 802px;
    }

    .softwareDevPlaceholder {
        min-height: 1625px;
    }

    .contactFormPlaceholder {
        min-height: 619px;
    }

    .footerPlaceholder {
        min-height: 353px;
    }
}